<template>
  <v-container fluid class="delivery-service-view">
    <Breadcrumb :items="breadCrumbs" />
    <DeliveryServiceSelector :fetchedCategory="category" />
  </v-container>
</template>
<style lang="scss">
.delivery-service-view {
  .delivery-service-selector {
    padding: 0px;
  }
}
</style>
<script>
import DeliveryServiceSelector from "@/components/delivery/DeliveryServiceSelector.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import { mapState } from "vuex";

export default {
  name: "DeliveryServiceView",
  data() {
    return {};
  },
  components: {
    DeliveryServiceSelector,
    Breadcrumb
  },
  computed: {
    ...mapState({
      category: ({ category }) => category.category
    }),
    breadCrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      const breadCrumbs = [{ href: "/spesa-online", text: "Homepage" }];
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = "";
      // let lastIndexFound = 0;

      for (let i = 1; i < pathArray.length; ++i) {
        // from 1 to skip "Area personale"
        breadcrumb = `${breadcrumb}${"/"}${pathArray[i]}`;
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
        ) {
          breadCrumbs.push({
            href: breadcrumb,
            // i !== 0 && pathArray[i - (i - lastIndexFound)]
            //   ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
            //   : breadcrumb,
            exact: false,
            disabled: i + 1 === pathArray.length,
            text: this.$route.matched[i].meta.breadCrumb || pathArray[i]
          });
          // lastIndexFound = i;
          // breadcrumb = "";
        }
      }
      return breadCrumbs;
    }
  }
};
</script>
