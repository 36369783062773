<template>
  <v-layout fill-height column mt-5 class="delivery-service-selector">
    <ebsn-meta
      :target="category"
      path="category_info.TITLE"
      class="text-center"
      tag="h1"
    ></ebsn-meta>
    <ebsn-meta
      :target="category"
      path="category_info.DESCRIPTION"
      class="text-center mt-2"
    ></ebsn-meta>
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
      :container="false"
    />

    <v-row class="my-3 tabs">
      <v-col
        cols="12"
        sm="6"
        :md="deliveryServiceButtonsLength"
        class="px-2"
        v-for="service in deliveryServices"
        :key="service.deliveryServiceId"
      >
        <v-card
          class="delivery-service h-100 d-flex flex-column justify-space-start"
          :class="
            service.deliveryServiceId === selectedDeliveryServiceId &&
            cart.shippingAddress.autoAssigned != true
              ? `${service.cssClass} selected`
              : `${service.cssClass}`
          "
          outlined
        >
          <img
            :src="service.iconSource"
            :title="service.name"
            :alt="service.name"
            width="150"
            height="150"
            class="m-auto mt-4 mb-0"
          />
          <v-card-title class="black--text">
            {{ service.name }}
          </v-card-title>
          <v-card-text class="delivery-service-content" v-html="service.descr">
            <!-- example delivery service descr -->
            <!-- <v-card-text class="delivery-service-content"> -->
            <!-- <p class="delivery-service-descr">
              La spesa subito a casa tua!
            </p>
            <div class="d-flex font-weight-bold mt-6">
              <span class="second-title text-h3 primary--text ">2,90€</span>
            </div>

            <v-list class="service-property">
              <v-list-item class="px-0">
                <v-list-item-content>
                  <div class="d-flex">
                    <v-icon color="primary">$complete</v-icon>
                    Paga online e consegna entro 1 ora dal momento dell'ordine
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item class="px-0">
                <v-list-item-content>
                  <div class="d-flex">
                    <v-icon color="primary">$complete</v-icon>
                    Orario del servizio: dalle 16:00 alle 20:00
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item class="px-0">
                <v-list-item-content>
                  <div class="d-flex">
                    <v-icon color="primary">$complete</v-icon>
                    Minimo d'ordine 20€
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
            </v-list> -->
          </v-card-text>
          <v-spacer />
          <v-card-actions class="">
            <v-btn
              color="primary"
              block
              depressed
              @click="selectDeliveryService(service)"
            >
              {{ $t("deliverySelector.selectBtn") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
      :container="false"
    />
  </v-layout>
</template>
<style lang="scss">
.delivery-service-selector {
  padding: 30px;
  .tabs {
    .delivery-service {
      &.selected {
        border-color: $primary;
      }
    }
  }
}
</style>
<script>
// @ is an alias to /src

import StoreService from "~/service/storeService";
import CategoryService from "~/service/categoryService";

import { mapState, mapGetters } from "vuex";

export default {
  name: "DeliveryServiceSelector",
  data() {
    return {
      store: {},
      deliveryService: null,
      category: {}
    };
  },
  params: ["fetchedCategory"],
  props: {
    fetchedCategory: { type: Object, required: false }
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart,
      selectedService: ({ cart }) => cart.selectedService
    }),
    deliveryServiceButtonsLength() {
      return 12 / this.deliveryServices.length;
    },

    selectedDeliveryServiceId() {
      return this.deliveryService ? this.deliveryService.deliveryServiceId : 0;
    },
    deliveryServices() {
      if (this.store && this.store.deliveryServices) {
        return this.store.deliveryServices.filter(button => {
          return button.showTab;
        });
      }
      return [];
    }
  },
  methods: {
    selectDeliveryService(deliveryService) {
      this.$router.push({
        name: "AddressSelectorView",
        query: {
          deliveryServiceId: deliveryService.deliveryServiceId,
          back: this.$route.query.back
        }
      });
    },
    async fetchStore() {
      let _this = this;
      let store = await StoreService.getStoreById(_this.cart.store.storeId);
      _this.store = store;

      _this.store.deliveryServices.find(deliveryService => {
        if (
          deliveryService.deliveryServiceId ==
          _this.cart.shippingAddress.deliveryServiceId
        ) {
          _this.deliveryService = deliveryService;
        }
      });
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "delivery-service-selection"
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.fetchStore();
    if (this.fetchedCategory) {
      this.category = this.fetchedCategory;
    } else {
      this.fetchCategory();
    }
  }
};
</script>
